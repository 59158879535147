import React, { useCallback } from "react";
import "./App.css";

import Logo from "./assets/logo.png";

function App() {
  const onClickNavigateToMain = useCallback(() => {
    window.location.href = "https://yyjlincoln.com";
  }, []);
  return (
    <div className="flex h-screen w-screen font-source text-theme tracking-tighter flex-col xl:flex-row justify-center items-center md:items-stretch">
      <div className="relative xl:ml-24 lg:ml-20 md:ml-16 text-center md:text-left space-y-10 px-10">
        {/* Font layout */}
        <div className="md:mt-32 xl:text-6xl lg:text-5xl md:text-4xl text-3xl flex flex-col">
          <div className="font-source font-bold">Be a dreamer,</div>
          <div className="font-source font-bold">Reach for the stars.</div>
        </div>
        <div>
          <div className="font-extrabold tracking-tight font-noto">
            一起做个梦吧，
          </div>
          <div className="font-extrabold tracking-tight font-noto">
            去追求美好的东西！
          </div>
        </div>

        <div
          className="font-source p-1 border-theme border-b hover:rounded-md w-fit cursor-pointer select-none mx-auto md:mx-0 transition-all hover:bg-theme hover:text-white"
          onClick={onClickNavigateToMain}
        >
          @yyjlincoln
        </div>
      </div>
      <div className="flex-1 md:relative md:bottom-auto md:h-auto overflow-hidden min-w-[120px] absolute left-0 top-0 right-0 bottom-0 h-screen select-none pointer-events-none">
        <img
          src={Logo}
          alt="Logo"
          className="absolute -bottom-1/4 w-full h-auto max-w-2xl right-0 md:mx-0"
        />
      </div>
    </div>
  );
}

export default App;
